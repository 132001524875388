<template>
  <el-main>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="京东商品列表" name="1">
        <el-form
          class="projectFrom"
          :model="queryParamsGoodsSearch"
          ref="queryParamsGoodsSearch"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsGoodsSearch.keywords"
              placeholder="请输入商品标题关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="最低价" prop="startPrice">
            <el-input
              type="number"
              v-model="queryParamsGoodsSearch.startPrice"
              placeholder="请输入最低价"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="最高价" prop="endPrice">
            <el-input
              type="number"
              v-model="queryParamsGoodsSearch.endPrice"
              placeholder="请输入最高价"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-select
              v-model="queryParamsGoodsSearch.sort"
              class="childWidth"
              filterable
              placeholder="请选择排序方式"
              clearable
            >
              <el-option
                v-for="item in goodsSearchSorts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="是否京东物流" prop="filterJdwl">
            <el-select
              v-model="queryParamsGoodsSearch.filterJdwl"
              class="childWidth"
              filterable
              placeholder="请选择排序方式"
              clearable
            >
              <el-option
                v-for="item in goodsSearchJdwls"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="是否有货" prop="filterStore">
            <el-select
              v-model="queryParamsGoodsSearch.filterStore"
              class="childWidth"
              filterable
              placeholder="请选择排序方式"
              clearable
            >
              <el-option
                v-for="item in goodsSearchStores"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <!-- <el-form-item label="筛选条件" prop="filters">
            <el-select
              v-model="queryParamsGoodsSearch.filters"
              class="childWidth"
              filterable
              placeholder="请选择筛选条件"
              clearable
              multiple
            >
              <el-option
                v-for="item in filtersSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          
          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button
              type="primary"
              @click="
                getExternalDataGoodsSearch(1, queryParamsGoodsSearch.pageSize)
              "
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="goodsSearchTableData != null">
          <!-- 表格 -->
          <el-table border :data="goodsSearchTableData">
            <el-table-column label="商品标题" prop="warename">
              <template slot-scope="scope">
                {{ scope.row.Content.warename }}
              </template>
            </el-table-column>
            <el-table-column label="属性" prop="CustomAttrList">
              <template slot-scope="scope">
                {{ scope.row.Content.CustomAttrList }}
              </template>
            </el-table-column>
            <el-table-column label="颜色" prop="color">
              <template slot-scope="scope">
                {{ scope.row.Content.color }}
              </template>
            </el-table-column>

            <el-table-column
              label="价格"
              prop="dredisprice"
              width="110"
            ></el-table-column>
            <el-table-column
              label="店铺名"
              prop="shop_name"
              width="110"
            ></el-table-column>
            <!-- <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column> -->
            <el-table-column label="商品图" prop="img" width="100">
              <template slot-scope="scope">
                <img class="picImg" :src="scope.row.Content.imageurl" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="商品链接" prop="url" width="100">
              <template slot-scope="scope">
                <el-link
                  :href="scope.row.toItemLink"
                  target="_blank"
                  :underline="false"
                  >商品链接</el-link
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openShopInfo(scope.row)"
                  >查看店铺信息</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChangeGoodsSearch"
              @current-change="handleCurrentChangeGoodsSearch"
              :current-page="this.queryParamsGoodsSearch.pageNum"
              :page-sizes="[5, 10, 20]"
              :page-size="this.queryParamsGoodsSearch.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.queryParamsGoodsSearch.total"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="店铺基本信息" name="2">
        <!-- <el-form
          class="projectFrom"
          :model="queryParamsShoInfo"
          ref="queryParamsShoInfo"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item label="店铺全名" prop="shopId">
            <el-input
              type="text"
              v-model="queryParamsShoInfo.shopId"
              :placeholder="sellerNicknamePlaceholder"
              width="200"
              clearable
              @change="sellerNicknameChange"
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right"> 
            <el-button type="primary" @click="getExternalData(1)"
              >查询</el-button
            >
          </div>
        </el-form> -->
        <div v-if="detail != null" class="a">
          <el-card class="box-card" v-if="detail != null">
            <!-- <el-divider content-position="center">基本信息</el-divider> -->
            <ul class="bar" style="list-style-type: none">
              <li>
                <b>logo:</b>
                <img
                  :src="detail.shop_logoUrlNew"
                  width="80px"
                  height="80px"
                  alt
                />
              </li>
              <li>
                <b>店铺名：</b>
                <div class="">
                  <span>{{ detail.shop_shopName }}</span>
                  <el-button
                    type="primary"
                    size="mini"
                    class="openShoProducts"
                    @click="openShoProducts()"
                    >查看本店铺商品</el-button
                  >
                </div>
              </li>
              
              <!-- <li>
                <b>所在地：</b>
                <span>{{ detail.prov }} {{ detail.city }}</span>
              </li>
              <li>
                <b>品类：</b>
                <span>{{ detail.categryNames }}</span>
              </li> -->
              
              
              <li>
                  <b>评分：</b>
                  <span> 用户评价:{{ detail.shop_userEvaluateScore }} {{ detail.shop_commentFactorScoreRankGrade }}</span>
                  <span> 物流履约:{{ detail.shop_logisticsLvyueScore }} {{ detail.shop_logisticsFactorScoreRankGrade }}</span>
                  <span> 售后服务:{{ detail.shop_afterServiceScore }} {{ detail.shop_afsFactorScoreRankGrade }}</span>
                </li>
 

              <li>
                <b>商品数量：</b>
                <span> {{ detail.shop_totalNum }}</span>
              </li>
              <li>
                <b>粉丝数量：</b>
                <span> {{ detail.shop_fansNum }}</span>
              </li>
              <li>
                <b>销量：</b>
                <span> {{ detail.totalSold }}</span>
              </li>
            </ul>
          </el-card>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="店铺商品列表" name="3">
        <el-form
          class="projectFrom"
          :model="queryParamsShoProducts"
          ref="queryParamsShoProducts"
          label-width="120px"
          :rules="rules2"
        >
          <el-form-item label="店铺" prop="shopId">
            <el-select
              v-model="queryParamsShoProducts.shopId"
              class="childWidth"
              filterable
              placeholder="请选择店铺"
              @visible-change="getShopSelectList($event)"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in shopSelectList"
                :key="item.originalId"
                :label="item.name"
                :value="item.originalId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsShoProducts.keywords"
              placeholder="请输入商品标题关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item> 
          <el-form-item label="排序" prop="sort">
            <el-select
              v-model="queryParamsShoProducts.sort"
              class="childWidth"
              filterable
              placeholder="请选择排序方式"
              clearable
            >
              <el-option
                v-for="item in goodsSearchSorts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button
              type="primary"
              @click="
                getExternalDataProductList(1, queryParamsShoInfo.pageSize)
              "
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="tableData != null">
          <!-- 表格 -->
          <el-table border :data="tableData">
            <el-table-column label="商品标题" prop="warename">
              <template slot-scope="scope">
                {{ scope.row.Content.warename }}
              </template>
            </el-table-column>
            <el-table-column label="属性" prop="CustomAttrList">
              <template slot-scope="scope">
                {{ scope.row.Content.CustomAttrList }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="颜色" prop="color">
              <template slot-scope="scope">
                {{ scope.row.Content.color }}
              </template>
            </el-table-column> -->
            <el-table-column
              label="价格"
              prop="dredisprice"
              width="110"
            ></el-table-column>
            <!-- <el-table-column
              label="现价"
              prop="price"
              width="110"
            ></el-table-column> -->
            <!-- <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column> -->
            <el-table-column label="商品图" prop="img" width="100">
              <template slot-scope="scope">
                <img class="picImg" :src="scope.row.Content.imageurl" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="商品链接" prop="url" width="100">
              <template slot-scope="scope">
                <el-link
                  :href="scope.row.toItemLink"
                  target="_blank"
                  :underline="false"
                  >商品链接</el-link
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openGoodsFeedbackList(scope.row)"
                  >查看商品评价</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="this.queryParamsShoProducts.pageNum"
              :page-sizes="[10, 20]"
              :page-size="this.queryParamsShoProducts.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.queryParamsShoProducts.total"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="商品评价" name="4">
        <el-form
          class="projectFrom"
          :model="queryParamsGoodsFeedbackList"
          ref="queryParamsGoodsFeedbackList"
          label-width="120px"
          :rules="rules2"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsGoodsFeedbackList.keywords"
              placeholder="请输入商品评价关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-select
              v-model="queryParamsGoodsFeedbackList.sort"
              class="childWidth"
              placeholder="请选择排序"
              clearable
            >
              <el-option
                v-for="item in goodsFeedbackSorts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="评价类型" prop="score">
            <el-select
              v-model="queryParamsGoodsFeedbackList.score"
              class="childWidth"
              placeholder="请选择内容类型"
              clearable
            >
              <el-option
                v-for="item in goodsFeedbackTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="只看当前商品" prop="onlySku">
            <el-select
              v-model="queryParamsGoodsFeedbackList.onlySku"
              class="childWidth"
              placeholder="请选择内容类型"
              clearable
            >
              <el-option
                v-for="item in goodsFeedbackOnlySku"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button
              type="primary"
              @click="
                getExternalDataGoodsFeedbackList(
                  1,
                  queryParamsGoodsFeedbackList.pageSize
                )
              "
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="goodsFeedbackTableData != null">
          <!-- 表格 -->
          <el-table border :data="goodsFeedbackTableData">
            
            <el-table-column label="评价内容" prop="content"></el-table-column>
            <el-table-column
              label="商品信息"
              prop="referenceName"
            ></el-table-column>
            <el-table-column
              label="评价人"
              prop="userNick"
              width="60"
            ></el-table-column>
            <el-table-column
              label="评价人等级"
              prop="userLevelName"
              width="90"
            ></el-table-column>
                <el-table-column
              label="评价来源"
              prop="userClientShow"
            ></el-table-column>
            <!-- <el-table-column label="商品图" prop="img">
                <template slot-scope="scope"> 
                  <span> {{JSON.String(scope.row.skuMap) }} </span>
                </template>
              </el-table-column> -->
            <el-table-column
              label="评价时间"
              prop="creationTime"
              width="100"
            ></el-table-column>
            <el-table-column label="商品图" prop="img">
              <template slot-scope="scope">
                <img class="picImg" :src="scope.row.referenceImage" alt=""> 
              </template>
            </el-table-column>
            <!-- <el-table-column label="商品链接" prop="url">
                <template slot-scope="scope">
                  <el-link
                    :href="scope.row.url"
                    target="_blank"
                    :underline="false"
                    >商品链接</el-link
                  >
                </template>
              </el-table-column> -->
          </el-table>

          <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChangeGoodsFeedback"
              @current-change="handleCurrentChangeGoodsFeedback"
              :current-page="this.queryParamsGoodsFeedbackList.pageNum"
              :page-sizes="[10, 20]"
              :page-size="this.queryParamsGoodsFeedbackList.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.queryParamsGoodsFeedbackList.total"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-main>
</template>

<script>
import { getExternalData, getShopSelectList } from "@/api/externalData";
import { formatDate } from "@/common/date";

export default {
  name: "jdShop",
  data() {
    return {
      loading: true,
      detail: null,
      queryParamsShoInfo: {
        //店铺基本信息
        providerName: "jd.shop.basic-info",
        shopId: "", //775176
        venderId: null,
      },
      queryParamsShoProducts: {
        startPrice: null,
        endPrice: null,
        keywords: "",
        providerName: "jd.shop.goods-list",
        pageSize: 10,
        pageNum: 1,
        shopId: null,
        sort: 3,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParamsGoodsSearch: {
        startPrice: null,
        endPrice: null,
        keywords: "闪魔", //iphone11
        providerName: "jd.goods.search",
        pageSize: 10,
        pageNum: 1,
        loc: null, //所在地，请填写省份或者城市名称
        sort: 0,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
        filterCategory: null,
      },
      queryParamsGoodsFeedbackList: {
        
        keywords: "",
        providerName: "jd.goods.feedback-list",
        pageSize: 10,
        pageNum: 1,
        productId: null,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      activeNames: ["1"],
      // queryParamsShoInfo: {
      //   total: 0,
      //   pageNum: 1,
      //   pageSize: 10,
      //   pageCount: 1,
      // },
      // queryParamsGoodsSearch: {
      //   total: 0,
      //   pageNum: 1,
      //   pageSize: 10,
      //   pageCount: 1,
      // },
      // queryParamsGoodsFeedbackList: {
      //   total: 0,
      //   pageNum: 1,
      //   pageSize: 10,
      //   pageCount: 1,
      // },
      tableData: null,
      goodsSearchTableData: null,
      goodsFeedbackTableData: null,
      shopSelectList: null,
      rules: {
        sellerNickname: [
          { required: true, message: "请输入店铺名", trigger: "blur" },
        ],
      },
      rules2: {
        shopId: [{ required: true, message: "请选择店铺", trigger: "change" }],
        keywords: [
          { required: false, message: "请输入商品标题关键词", trigger: "blur" },
        ],
      },
      rules3: {
        keywords: [
          { required: true, message: "请输入商品关键词", trigger: "blur" },
        ],
      },
      rules4: {
        keywords: [
          { required: false, message: "请输入商品关键词", trigger: "blur" },
        ],
      },
      icons: [
        "#icon-el-icon-xin",
        "#icon-el-icon-zuan",
        "#icon-el-icon-lanhuangguan",
        "#icon-el-icon-jinhuangguan",
      ],
      sellerNicknamePlaceholder: "AIRMETER官方旗舰店",
      goodsSearchSorts: [
        {
          id: 0,
          name: "综合排序",
        },
        {
          id: 1,
          name: "销量排序",
        },
        {
          id: 2,
          name: "评价排序",
        },
        {
          id: 3,
          name: "新品排序",
        },
        {
          id: 4,
          name: "价格由高到低",
        },
        {
          id: 5,
          name: "价格由低到高",
        },
        
      ],
      goodsSearchJdwls: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "京东物流",
        }, 
      ],
      goodsSearchStores: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "仅显示有货",
        }, 
      ],
      filtersSelectList: [
        {
          id: 1,
          name: "免邮",
        },
        {
          id: 2,
          name: "天猫",
        },
        {
          id: 3,
          name: "全球购",
        },
        {
          id: 4,
          name: "消保",
        },
        {
          id: 5,
          name: "手机专享",
        },
        {
          id: 6,
          name: "淘金币",
        },
        {
          id: 7,
          name: "货到付款",
        },
        {
          id: 8,
          name: "7天退换",
        },
        {
          id: 9,
          name: "促销",
        },
      ],
      goodsFeedbackSorts: [
        {
          id: 5,
          name: "默认排序",
        },
        {
          id: 6,
          name: "时间排序",
        },
      ],
      goodsFeedbackTypes: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "差评",
        },
        {
          id: 2,
          name: "中评",
        },
        {
          id: 3,
          name: "好评",
        }, 
      ],
      goodsFeedbackOnlySku:[
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        }, 
      ],
      goodsFeedbackTableData1: null,
      shopSelectListParams:{
        name: null,
        type:2
      }
    };
  },
  props:{
    searchKeywords:String
  },
   watch: {
    searchKeywords: {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old);
        this.queryParamsGoodsSearch.keywords = newVal;
        this.getExternalDataGoodsSearch(1, this.queryParamsGoodsSearch.pageSize);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  mounted() {},
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  methods: {
    async getExternalData1(list, info) {
      this[`${list}`] = await this.$store
        .dispatch("getExternalData", info)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    openShoProducts() {
      console.log(this.detail);
      this.getShopSelectList(true);
      this.queryParamsShoProducts.shopId = this.detail.shop_shopId;
      this.getExternalDataProductList(
        this.queryParamsShoInfo.pageNum,
        this.queryParamsShoInfo.pageSize
      );
      this.activeNames = ["3"];
    },
    openGoodsFeedbackList(data) {
      console.log(data.wareid);
      this.queryParamsGoodsFeedbackList.productId = data.wareid;
      this.getExternalDataGoodsFeedbackList(
        this.queryParamsGoodsFeedbackList.pageNum,
        this.queryParamsGoodsFeedbackList.pageSize
      );

      this.activeNames = ["4"];
    },
    openShopInfo(data) {
      // console.log(data.userId);
      this.queryParamsShoInfo.shopId = data.shop_id;
      this.rules.sellerNickname[0].required = false;
      this.getExternalData();
      this.activeNames = ["2"];
      this.sellerNicknamePlaceholder = null;
    },
    sellerNicknameChange(data) {
      console.log(data);
      if (this.sellerNicknamePlaceholder != null) {
        this.sellerNicknamePlaceholder = null;
      }
    },
    getShopSelectList(cb) {
      if (cb) { 
        getShopSelectList(this.shopSelectListParams).then((response) => {
          if (response.success) {
            this.shopSelectList = response.data;
          } else {
            this.shopSelectList = null;
          }
        });
      }
    },
    handleChange(val) {
      console.log(val);
    },
    getExternalData() {
      getExternalData(this.queryParamsShoInfo).then((response) => {
        if (response.success) {
          let detail = response.data;
          if (detail) {
            if (
              detail.shop_logoUrlNew.indexOf("http") ||
              detail.shop_logoUrlNew.indexOf("https")
            ) {
              // detail.shop_logoUrlNew = "https:" + detail.shopSignImg;
              // detail.diy_shopLatestSignUrl = "https:" + detail.wapIcon;
            }
            // detail.rankNum = Number(detail.rankNum);
            // detail.rankType = Number(detail.rankType);

            this.detail = detail;
          } else {
            this.detail = null;
            this.$message({ type: "warning", message: "暂无店铺数据!" });
          }
        } else {
          this.detail = null;
        }
      });
    },
    getExternalDataProductList(num, pageSize) {
      this.$refs["queryParamsShoProducts"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsShoProducts.pageNum = num;
          this.queryParamsShoProducts.pageSize = pageSize;
          // this.queryParamsShoProducts.shopId = this.detail.id;
          getExternalData(this.queryParamsShoProducts).then((response) => {
            // console.log(this.tableData);
            if (response.success) {
              let tableData = response.data.Paragraph;
              let page = response.data.Summary;
              tableData.forEach((item, index) => {
                if (
                  item.Content.imageurl &&
                  (item.Content.imageurl.indexOf("http") ||
                    item.Content.imageurl.indexOf("https"))
                ) {
                  item.Content.imageurl =
                    "https://img13.360buyimg.com/imgzone/" +
                    item.Content.imageurl;
                }
              });
              this.tableData = tableData;
              this.queryParamsShoProducts.pageNum = Number(page.Page.PageIndex);
              this.queryParamsShoProducts.pageSize = Number(page.Page.PageSize);
              this.queryParamsShoProducts.total = Number(page.ResultCount);
              this.queryParamsShoProducts.pageCount = Number(page.Page.PageCount);
            } else {
              this.tableData = null;
            }
          });
        }
      });
    },
    getExternalDataGoodsSearch(num, pageSize) {
      this.$refs["queryParamsGoodsSearch"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsGoodsSearch.pageNum = num;
          this.queryParamsGoodsSearch.pageSize = pageSize;
          getExternalData(this.queryParamsGoodsSearch).then((response) => {
            // console.log(this.tableData);
            if (response.success) {
              let tableData = response.data.Paragraph;
              let page = response.data.Summary;
              tableData.forEach((item, index) => {
                if (
                  item.Content.imageurl &&
                  (item.Content.imageurl.indexOf("http") ||
                    item.Content.imageurl.indexOf("https"))
                ) {
                  item.Content.imageurl =
                    "https://img13.360buyimg.com/imgzone/" +
                    item.Content.imageurl;
                }
              });
              this.goodsSearchTableData = tableData;
              this.queryParamsGoodsSearch.pageNum = Number(page.Page.PageIndex);
              this.queryParamsGoodsSearch.pageSize = Number(page.Page.PageSize);
              this.queryParamsGoodsSearch.total = Number(page.ResultCount);
              this.queryParamsGoodsSearch.pageCount = Number(
                page.Page.PageCount
              );
            } else {
              this.goodsSearchTableData = null;
            }
          });
        }
      });
    },
    getExternalDataGoodsFeedbackList(num, pageSize) {
      this.$refs["queryParamsGoodsFeedbackList"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsGoodsFeedbackList.pageNum = num;
          this.queryParamsGoodsFeedbackList.pageSize = pageSize;
          getExternalData(this.queryParamsGoodsFeedbackList).then(
            (response) => {
              // console.log(this.tableData);
              if (response.success) {
                let tableData = response.data.comments;
                tableData.forEach((item, index) => {
                  if (item.referenceImage.indexOf("http") || item.referenceImage.indexOf("https")) {
                        item.referenceImage =
                    "https://img13.360buyimg.com/imgzone/" +
                    item.referenceImage;
                  } 
                });
                

            
                this.goodsFeedbackTableData = tableData;
                // this.queryParamsGoodsFeedbackList.pageNum = Number(response.data.page);
                // this.queryParamsGoodsFeedbackList.pageSize = Number(
                //   response.data.pageSize
                // );
                this.queryParamsGoodsFeedbackList.total = Number(
                  response.data.productCommentSummary.GoodCount
                );
                this.queryParamsGoodsFeedbackList.pageCount = Number(
                  response.data.maxPage
                );
              } else {
                this.goodsFeedbackTableData = null;
              }
            }
          );
        }
      });
    },
    handleSizeChange(val) {
      console.log(val);
      this.getExternalDataProductList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.queryParamsShoInfo.pageCount) {
        val = this.queryParamsShoInfo.pageCount;
      }
      this.getExternalDataProductList(val, this.queryParamsShoInfo.pageSize);
    },
    handleSizeChangeGoodsSearch(val) {
      console.log(val);
      this.getExternalDataGoodsSearch(1, val);
    },
    handleCurrentChangeGoodsSearch(val) {
      console.log(val);
      if (val > this.queryParamsGoodsSearch.pageCount) {
        val = this.queryParamsGoodsSearch.pageCount;
      }
      this.getExternalDataGoodsSearch(
        val,
        this.queryParamsGoodsSearch.pageSize
      );
    },
    handleSizeChangeGoodsFeedback(val) {
      console.log(val);
      this.getExternalDataGoodsFeedbackList(1, val);
    },
    handleCurrentChangeGoodsFeedback(val) {
      console.log(val);
      if (val > this.queryParamsGoodsFeedbackList.pageCount) {
        val = this.queryParamsGoodsFeedbackList.pageCount;
      }
      this.getExternalDataGoodsFeedbackList(
        val,
        this.queryParamsGoodsFeedbackList.pageSize
      );
    },
  },

  
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}

.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 48%;
    // min-height: 30px;
    // line-height: 50px;
    // margin: 2px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
      // margin-right: 5px;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    div {
      // width: 50%;
      b {
        min-width: 120px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
  }
}

//
.imgf {
}
.picImg {
  width: 60px;
}
.shopDSRScoremg {
  color: #909399;
  font-size: 14px;
  margin-left: 5px;
}
.openShoProducts{
  margin-left: 5px;
}
</style>
