<template>
  <el-main>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="淘宝商品列表" name="1">
        <el-form
          class="projectFrom"
          :model="queryParamsGoodsSearch"
          ref="queryParamsGoodsSearch"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsGoodsSearch.keywords"
              placeholder="请输入商品标题关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="最低价" prop="startPrice">
            <el-input
              type="number"
              v-model="queryParamsGoodsSearch.startPrice"
              placeholder="请输入最低价"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="最高价" prop="endPrice">
            <el-input
              type="number"
              v-model="queryParamsGoodsSearch.endPrice"
              placeholder="请输入最高价"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-select
              v-model="queryParamsGoodsSearch.sort"
              class="childWidth"
              filterable
              placeholder="请选择排序方式"
              clearable
            >
              <el-option
                v-for="item in goodsSearchSorts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="筛选条件" prop="filters">
            <el-select
              v-model="queryParamsGoodsSearch.filters"
              class="childWidth"
              filterable
              placeholder="请选择筛选条件"
              clearable
              multiple
            >
              <el-option
                v-for="item in filtersSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在地" prop="loc">
            <el-input
              type="text"
              v-model="queryParamsGoodsSearch.loc"
              placeholder="请输入省份或者城市名称"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button
              type="primary"
              @click="
                getExternalDataGoodsSearch(1, queryParamsGoodsSearch.pageSize)
              "
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="goodsSearchTableData != null">
          <!-- 表格 -->
          <el-table border :data="goodsSearchTableData">
            <el-table-column label="商品标题" prop="title"></el-table-column>

            <el-table-column label="商品资料" prop="summaryTips">
              <template slot-scope="scope">
                <div class="flexFcAFs">
                  <span
                    v-for="(item, index) in scope.row.summaryTips"
                    :key="index"
                    >{{ index + 1 }}. {{ item }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
                label="原价"
                prop="original_price"
              ></el-table-column> -->
            <el-table-column label="价格" prop="price" width="120">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.priceShow.unit
                  }}{{ scope.row.priceShow.price }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="店铺名"
              prop="nick"
              width="110"
            ></el-table-column>
            <el-table-column
              label="销量"
              prop="sold"
              width="120"
            ></el-table-column>
            <el-table-column label="商品图" prop="pic_path" width="100">
              <template slot-scope="scope">
                <img class="picImg" :src="scope.row.pic_path" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="商品链接" prop="auctionURL" width="100">
              <template slot-scope="scope">
                <el-link
                  :href="scope.row.auctionURL"
                  target="_blank"
                  :underline="false"
                  >商品链接</el-link
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openShopInfo(scope.row)"
                  >查看店铺信息</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChangeGoodsSearch"
              @current-change="handleCurrentChangeGoodsSearch"
              :current-page="this.queryParamsGoodsSearch.pageNum"
              :page-sizes="[5, 10, 20]"
              :page-size="this.queryParamsGoodsSearch.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.queryParamsGoodsSearch.total"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="店铺基本信息" name="2">
        <el-form
          class="projectFrom"
          :model="queryParamsShoInfo"
          ref="queryParamsShoInfo"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item label="店铺全名" prop="sellerNickname">
            <el-input
              type="text"
              v-model="queryParamsShoInfo.sellerNickname"
              :placeholder="sellerNicknamePlaceholder"
              width="200"
              clearable
              @change="sellerNicknameChange"
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getExternalData(1)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div v-if="detail != null" class="a">
          <el-card class="box-card" v-if="detail != null">
            <!-- <el-divider content-position="center">基本信息</el-divider> -->
            <ul class="bar" style="list-style-type: none">
              <li>
                <b>logo:</b>
                <img :src="detail.picUrl" width="80px" height="80px" alt />
              </li>
              <li>
                <b>店铺名：</b>
                <div class="">
                  <span>{{ detail.title }}</span>
                  <el-button
                    type="primary"
                    size="mini"
                    class="openShoProducts"
                    @click="openShoProducts()"
                    >查看本店铺商品</el-button
                  >
                </div>
              </li>
              <li>
                <b>卖家电话：</b>
                <span>{{ detail.phoneExt }}</span>
              </li>
              <li>
                <b>是否天猫：</b>
                <span>{{ detail.isMall == "true" ? "是" : "否" }}</span>
              </li>

              <li>
                <b>所在地：</b>
                <span>{{ detail.prov }} {{ detail.city }}</span>
              </li>
              <li>
                <b>品类：</b>
                <span>{{ detail.categryNames }}</span>
              </li>
              <li>
                <b>店铺等级：</b>
                <span class="">
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-for="(item, index) in detail.rankNum"
                    :key="index"
                  >
                    <use :xlink:href="icons[detail.rankType - 1]"></use>
                  </svg>
                </span>
              </li>
              <li class="width100">
                <b>评分：</b>
                <span>
                  描述相符:{{ detail.shopDSRScore.merchandisScore }}
                  <span class="shopDSRScoremg"
                    >{{
                      detail.shopDSRScore.mg.indexOf("-") != "-1"
                        ? "低于同行"
                        : "高于同行"
                    }}{{
                      Number(
                        detail.shopDSRScore.mg.indexOf("-") != "-1"
                          ? detail.shopDSRScore.mg.slice(1)
                          : detail.shopDSRScore.mg
                      )
                    }}%</span
                  >
                </span>
                <span>
                  服务态度:{{ detail.shopDSRScore.serviceScore }}

                  <span class="shopDSRScoremg"
                    >{{
                      detail.shopDSRScore.sg.indexOf("-") != "-1"
                        ? "低于同行"
                        : "高于同行"
                    }}{{
                      Number(
                        detail.shopDSRScore.sg.indexOf("-") != "-1"
                          ? detail.shopDSRScore.sg.slice(1)
                          : detail.shopDSRScore.sg
                      )
                    }}%</span
                  >
                </span>
                <span>
                  物流服务:{{ detail.shopDSRScore.consignmentScore }}
                  <span class="shopDSRScoremg"
                    >{{
                      detail.shopDSRScore.cg.indexOf("-") != "-1"
                        ? "低于同行"
                        : "高于同行"
                    }}{{
                      Number(
                        detail.shopDSRScore.cg.indexOf("-") != "-1"
                          ? detail.shopDSRScore.cg.slice(1)
                          : detail.shopDSRScore.cg
                      )
                    }}%</span
                  >
                </span>
              </li>

              <li>
                <b>商品数量：</b>
                <span> {{ detail.productCount }}</span>
              </li>
              <li>
                <b>被关注/收藏次数：</b>
                <span> {{ detail.collectorCount }}</span>
              </li>
              <li>
                <b>销量：</b>
                <span> {{ detail.totalSold }}</span>
              </li>
            </ul>
          </el-card>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="店铺商品列表" name="3">
        <el-form
          class="projectFrom"
          :model="queryParamsShoProducts"
          ref="queryParamsShoProducts"
          label-width="120px"
          :rules="rules2"
        >
          <el-form-item label="店铺" prop="shopId">
            <el-select
              v-model="queryParamsShoProducts.shopId"
              class="childWidth"
              filterable
              placeholder="请选择店铺"
              @visible-change="getShopSelectList($event)"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in shopSelectList"
                :key="item.originalId"
                :label="item.name"
                :value="item.originalId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsShoProducts.keywords"
              placeholder="请输入商品标题关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="最低价" prop="startPrice">
            <el-input
              type="number"
              v-model="queryParamsShoProducts.startPrice"
              placeholder="请输入最低价"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="最高价" prop="endPrice">
            <el-input
              type="number"
              v-model="queryParamsShoProducts.endPrice"
              placeholder="请输入最高价"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-select
              v-model="queryParamsShoProducts.sort"
              class="childWidth"
              filterable
              placeholder="请选择排序方式"
              clearable
            >
              <el-option
                v-for="item in goodsSearchSorts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button
              type="primary"
              @click="
                getExternalDataProductList(1, queryParamsShoInfo.pageSize)
              "
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="tableData != null">
          <!-- 表格 -->
          <el-table border :data="tableData">
            <el-table-column label="商品标题" prop="title"></el-table-column>
            <el-table-column
              label="原价"
              prop="original_price"
              width="110"
            ></el-table-column>
            <el-table-column
              label="现价"
              prop="price"
              width="110"
            ></el-table-column>
            <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column>
            <el-table-column label="商品图" prop="img" width="100">
              <template slot-scope="scope">
                <img class="picImg" :src="scope.row.img" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="商品链接" prop="url" width="100">
              <template slot-scope="scope">
                <el-link
                  :href="scope.row.url"
                  target="_blank"
                  :underline="false"
                  >商品链接</el-link
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openGoodsFeedbackList(scope.row)"
                  >查看商品评价</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="this.queryParamsShoProducts.pageNum"
              :page-sizes="[10, 20]"
              :page-size="this.queryParamsShoProducts.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.queryParamsShoProducts.total"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="商品评价" name="4">
        <el-form
          class="projectFrom"
          :model="queryParamsGoodsFeedbackList"
          ref="queryParamsGoodsFeedbackList"
          label-width="120px"
          :rules="rules2"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsGoodsFeedbackList.keywords"
              placeholder="请输入商品评价关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-select
              v-model="queryParamsGoodsFeedbackList.sort"
              class="childWidth"
              placeholder="请选择排序"
              clearable
            >
              <el-option
                v-for="item in goodsFeedbackSorts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="评价类型" prop="type">
            <el-select
              v-model="queryParamsGoodsFeedbackList.type"
              class="childWidth"
              placeholder="请选择内容类型"
              clearable
            >
              <el-option
                v-for="item in goodsFeedbackTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button
              type="primary"
              @click="
                getExternalDataGoodsFeedbackList(
                  1,
                  queryParamsGoodsFeedbackList.pageSize
                )
              "
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="goodsFeedbackTableData != null">
          <!-- 表格 -->
          <el-table border :data="goodsFeedbackTableData">
            <el-table-column
              label="评价人"
              prop="userNick"
              width="60"
            ></el-table-column>
            <el-table-column label="评价内容" prop="feedback"></el-table-column>
            <el-table-column
              label="商品信息"
              prop="original_price"
            ></el-table-column>
            <!-- <el-table-column label="商品图" prop="img">
                <template slot-scope="scope"> 
                  <span> {{JSON.String(scope.row.skuMap) }} </span>
                </template>
              </el-table-column> -->
            <el-table-column
              label="评价时间"
              prop="feedbackDate"
              width="100"
            ></el-table-column>
            <el-table-column label="商品图" prop="img">
              <template slot-scope="scope">
                <div class="imgf">
                  <img
                    v-for="(item, index) in scope.row.feedPicPathList"
                    :key="index"
                    class="picImg"
                    :src="item"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="商品链接" prop="url">
                <template slot-scope="scope">
                  <el-link
                    :href="scope.row.url"
                    target="_blank"
                    :underline="false"
                    >商品链接</el-link
                  >
                </template>
              </el-table-column> -->
          </el-table>

          <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChangeGoodsFeedback"
              @current-change="handleCurrentChangeGoodsFeedback"
              :current-page="this.queryParamsGoodsFeedbackList.pageNum"
              :page-sizes="[10, 20]"
              :page-size="this.queryParamsGoodsFeedbackList.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.queryParamsGoodsFeedbackList.total"
            ></el-pagination>
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-main>
</template>

<script>
import { getExternalData, getShopSelectList } from "@/api/externalData";
import { formatDate } from "@/common/date";

export default {
  name: "tbShop",
  data() {
    return {
      loading: true,
      detail: null,
      queryParamsShoInfo: {
        //店铺基本信息
        sellerNickname: null, //周大生旗舰店,时尚前线饰品旗舰店
        providerName: "tb.shop.basic-info",
        shopId: null,
        sellerId: null,
      },
      queryParamsShoProducts: {
        startPrice: null,
        endPrice: null,
        keywords: "",
        providerName: "tb.shop.goods-list",
        pageSize: 10,
        pageNum: 1,
        shopId: null,
        sort: 3,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParamsGoodsSearch: {
        startPrice: null,
        endPrice: null,
        keywords: "iphone11", //iphone11
        providerName: "tb.goods.search",
        pageSize: 10,
        pageNum: 1,
        loc: null, //所在地，请填写省份或者城市名称
        sort: 3,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParamsGoodsFeedbackList: {
        keywords: "",
        providerName: "tb.goods.feedback-list",
        pageSize: 10,
        pageNum: 1,
        numIid: "",
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      activeNames: ["1"],
      // queryParamsShoInfo: {
      //   total: 0,
      //   pageNum: 1,
      //   pageSize: 10,
      //   pageCount: 1,
      // },
      // queryParamsGoodsSearch: {
      //   total: 0,
      //   pageNum: 1,
      //   pageSize: 10,
      //   pageCount: 1,
      // },
      // queryParamsGoodsFeedbackList: {
      //   total: 0,
      //   pageNum: 1,
      //   pageSize: 10,
      //   pageCount: 1,
      // },
      tableData: null,
      goodsSearchTableData: null,
      goodsFeedbackTableData: null,
      shopSelectList: null,
      rules: {
        sellerNickname: [
          { required: true, message: "请输入店铺名", trigger: "blur" },
        ],
      },
      rules2: {
        shopId: [{ required: true, message: "请选择店铺", trigger: "change" }],
        keywords: [
          { required: false, message: "请输入商品标题关键词", trigger: "blur" },
        ],
      },
      rules3: {
        keywords: [
          { required: true, message: "请输入商品关键词", trigger: "blur" },
        ],
      },
      rules4: {
        keywords: [
          { required: false, message: "请输入商品关键词", trigger: "blur" },
        ],
      },
      icons: [
        "#icon-el-icon-xin",
        "#icon-el-icon-zuan",
        "#icon-el-icon-lanhuangguan",
        "#icon-el-icon-jinhuangguan",
      ],
      sellerNicknamePlaceholder: "AIRMETER官方旗舰店",
      goodsSearchSorts: [
        {
          id: 1,
          name: "综合排序",
        },
        {
          id: 2,
          name: "新品排序",
        },
        {
          id: 3,
          name: "销量由高到低",
        },
        {
          id: 4,
          name: "销量由低到高",
        },
        {
          id: 5,
          name: "价格由高到低",
        },
        {
          id: 6,
          name: "价格由低到高",
        },
        {
          id: 7,
          name: "信用由高到低",
        },
        {
          id: 8,
          name: "信用由低到高",
        },
      ],
      filtersSelectList: [
        {
          id: 1,
          name: "免邮",
        },
        {
          id: 2,
          name: "天猫",
        },
        {
          id: 3,
          name: "全球购",
        },
        {
          id: 4,
          name: "消保",
        },
        {
          id: 5,
          name: "手机专享",
        },
        {
          id: 6,
          name: "淘金币",
        },
        {
          id: 7,
          name: "货到付款",
        },
        {
          id: 8,
          name: "7天退换",
        },
        {
          id: 9,
          name: "促销",
        },
      ],
      goodsFeedbackSorts: [
        {
          id: 1,
          name: "推荐排序",
        },
        {
          id: 2,
          name: "时间排序",
        },
      ],
      goodsFeedbackTypes: [
        {
          id: "all",
          name: "全部",
        },
        {
          id: "bad",
          name: "差评",
        },
        {
          id: "normal",
          name: "中评",
        },
        {
          id: "good",
          name: "好评",
        },
        {
          id: "append",
          name: "追评",
        },
        {
          id: "picture",
          name: "图片",
        },
        // {
        //   id: "video",
        //   name: "视频",
        // },
      ],
      goodsFeedbackTableData1: null,
      shopSelectListParams: {
         name: null,
         type:1
      },
    };
  },
  props:{
    searchKeywords:String
  },
  watch: {
    searchKeywords: {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old);
        this.queryParamsGoodsSearch.keywords = newVal;
        this.getExternalDataGoodsSearch(1, this.queryParamsGoodsSearch.pageSize);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  mounted() {},
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  methods: {
    async getExternalData1(list, info) {
      this[`${list}`] = await this.$store
        .dispatch("getExternalData", info)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    openShoProducts() {
      console.log(this.detail);
      this.getShopSelectList(true);
      this.queryParamsShoProducts.shopId = this.detail.id;
      this.getExternalDataProductList(
        this.queryParamsShoInfo.pageNum,
        this.queryParamsShoInfo.pageSize
      );
      this.activeNames = ["3"];
    },
    openGoodsFeedbackList(data) {
      console.log(data.item_id);
      this.queryParamsGoodsFeedbackList.numIid = data.item_id;
      this.getExternalDataGoodsFeedbackList(
        this.queryParamsGoodsFeedbackList.pageNum,
        this.queryParamsGoodsFeedbackList.pageSize
      );

      this.activeNames = ["4"];
    },
    openShopInfo(data) {
      console.log(data.userId);
      // this.queryParamsShoInfo.shopId = data.userId;
      this.queryParamsShoInfo.sellerId = data.userId;
      this.rules.sellerNickname[0].required = false;
      this.getExternalData();
      this.activeNames = ["2"];
      this.sellerNicknamePlaceholder = null;
    },
    sellerNicknameChange(data) {
      console.log(data);
      if (this.sellerNicknamePlaceholder != null) {
        this.sellerNicknamePlaceholder = null;
      }
    },
    getShopSelectList(cb) {
      if (cb) {
        getShopSelectList(this.shopSelectListParams).then((response) => {
          if (response.success) {
            this.shopSelectList = response.data;
          } else {
            this.shopSelectList = null;
          }
        });
      }
    },
    handleChange(val) {
      console.log(val);
    },
    getExternalData() {
      if (
        this.sellerNicknamePlaceholder != null &&
        !this.queryParamsShoInfo.sellerId
      ) {
        this.queryParamsShoInfo.sellerNickname = this.sellerNicknamePlaceholder;
      }
      this.$refs["queryParamsShoInfo"].validate((valid) => {
        if (valid) {
          getExternalData(this.queryParamsShoInfo).then((response) => {
            if (response.success) {
              let detail = response.data;
              if (detail) {
                if (
                  detail.shopSignImg.indexOf("http") ||
                  detail.shopSignImg.indexOf("https")
                ) {
                  detail.shopSignImg = "https:" + detail.shopSignImg;
                  detail.wapIcon = "https:" + detail.wapIcon;
                  detail.picUrl = "https:" + detail.picUrl;
                }
                detail.rankNum = Number(detail.rankNum);
                detail.rankType = Number(detail.rankType);

                this.detail = detail;
                if (!this.queryParamsShoInfo.sellerNickname) {
                  this.queryParamsShoInfo.sellerNickname = detail.title;
                }
              } else {
                this.detail = null;
                this.$message({ type: "warning", message: "暂无店铺数据!" });
              }
            } else {
              this.detail = null;
            }
          });
          this.rules.sellerNickname[0].required = true;
          this.sellerNicknamePlaceholder = null;
        }
      });
    },
    getExternalDataProductList(num, pageSize) {
      this.$refs["queryParamsShoProducts"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsShoProducts.pageNum = num;
          this.queryParamsShoProducts.pageSize = pageSize;
          // this.queryParamsShoProducts.shopId = this.detail.id;
          getExternalData(this.queryParamsShoProducts).then((response) => {
            // console.log(this.tableData);
            if (response.data.success) {
              let tableData = response.data.nodes;
              tableData.forEach((item, index) => {
                if (item.img.indexOf("http") || item.img.indexOf("https")) {
                  item.img = "https:" + item.img;
                }
                if (item.url.indexOf("http") || item.url.indexOf("https")) {
                  item.url = "https:" + item.url;
                }
              });
              this.tableData = tableData;
              this.queryParamsShoProducts.pageNum = response.data.current_page;
              this.queryParamsShoProducts.pageSize = response.data.page_size;
              this.queryParamsShoProducts.total = response.data.total_results;
              this.queryParamsShoProducts.pageCount = response.data.total_page;
            } else {
              this.tableData = null;
            }
          });
        }
      });
    },
    getExternalDataGoodsSearch(num, pageSize) {
      this.$refs["queryParamsGoodsSearch"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsGoodsSearch.pageNum = num;
          this.queryParamsGoodsSearch.pageSize = pageSize;
          getExternalData(this.queryParamsGoodsSearch).then((response) => {
            // console.log(this.tableData);
            if (response.success) {
              let tableData = response.data.itemsArray;
              // tableData.forEach((item, index) => {
              //   if (item.img.indexOf("http") || item.img.indexOf("https")) {
              //     item.img = "https:" + item.img;
              //   }
              //   if (item.url.indexOf("http") || item.url.indexOf("https")) {
              //     item.url = "https:" + item.url;
              //   }
              // });
              this.goodsSearchTableData = tableData;
              this.queryParamsGoodsSearch.pageNum = Number(response.data.page);
              this.queryParamsGoodsSearch.pageSize = Number(
                response.data.pageSize
              );
              this.queryParamsGoodsSearch.total = Number(
                response.data.totalResults
              );
              this.queryParamsGoodsSearch.pageCount = Number(
                response.data.totalPage
              );
            } else {
              this.goodsSearchTableData = null;
            }
          });
        }
      });
    },
    getExternalDataGoodsFeedbackList(num, pageSize) {
      this.$refs["queryParamsGoodsFeedbackList"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsGoodsFeedbackList.pageNum = num;
          this.queryParamsGoodsFeedbackList.pageSize = pageSize;
          getExternalData(this.queryParamsGoodsFeedbackList).then(
            (response) => {
              // console.log(this.tableData);
              if (response.success) {
                let tableData = response.data.rateList;
                // tableData.forEach((item, index) => {
                //   if (item.img.indexOf("http") || item.img.indexOf("https")) {
                //     item.img = "https:" + item.img;
                //   }
                //   if (item.url.indexOf("http") || item.url.indexOf("https")) {
                //     item.url = "https:" + item.url;
                //   }
                // });
                this.goodsFeedbackTableData = tableData;
                // this.queryParamsGoodsFeedbackList.pageNum = Number(response.data.page);
                // this.queryParamsGoodsFeedbackList.pageSize = Number(
                //   response.data.pageSize
                // );
                this.queryParamsGoodsFeedbackList.total = Number(
                  response.data.total
                );
                this.queryParamsGoodsFeedbackList.pageCount = Number(
                  response.data.totalPage
                );
              } else {
                this.goodsFeedbackTableData = null;
              }
            }
          );
        }
      });
    },
    handleSizeChange(val) {
      console.log(val);
      this.getExternalDataProductList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.queryParamsShoInfo.pageCount) {
        val = this.queryParamsShoInfo.pageCount;
      }
      this.getExternalDataProductList(val, this.queryParamsShoInfo.pageSize);
    },
    handleSizeChangeGoodsSearch(val) {
      console.log(val);
      this.getExternalDataGoodsSearch(1, val);
    },
    handleCurrentChangeGoodsSearch(val) {
      console.log(val);
      if (val > this.queryParamsGoodsSearch.pageCount) {
        val = this.queryParamsGoodsSearch.pageCount;
      }
      this.getExternalDataGoodsSearch(
        val,
        this.queryParamsGoodsSearch.pageSize
      );
    },
    handleSizeChangeGoodsFeedback(val) {
      console.log(val);
      this.getExternalDataGoodsFeedbackList(1, val);
    },
    handleCurrentChangeGoodsFeedback(val) {
      console.log(val);
      if (val > this.queryParamsGoodsFeedbackList.pageCount) {
        val = this.queryParamsGoodsFeedbackList.pageCount;
      }
      this.getExternalDataGoodsFeedbackList(
        val,
        this.queryParamsGoodsFeedbackList.pageSize
      );
    },
  },
 
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}

.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 48%;
    // min-height: 30px;
    // line-height: 50px;
    // margin: 2px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
      // margin-right: 5px;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    div {
      // width: 50%;
      b {
        min-width: 120px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
  }
}

//
.imgf {
}
.picImg {
  width: 60px;
}
.shopDSRScoremg {
  color: #909399;
  font-size: 14px;
  margin-left: 5px;
}
.openShoProducts {
  margin-left: 5px;
}
</style>
