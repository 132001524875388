<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="queryParams"
        ref="queryParams"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="关键词" prop="keywords">
          <el-input
            type="text"
            v-model="queryParams.keywords"
            placeholder="请输入关键词"
            width="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="平台" prop="providerNames">
          <el-select
            v-model="queryParams.providerNames"
            class="childWidth"
            filterable
            placeholder="请选择筛选条件"
            clearable
            multiple
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in providerNameSelectList"
              :key="item.providerName"
              :label="item.name"
              :value="item.providerName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <!-- <el-button @click="resetConditions">重置</el-button> -->
          <el-button type="primary" @click="getExternalData(1)">查询</el-button>
        </div>
      </el-form>

      <div class="flexFwAb providerNameList" >
        <hotWordList
        v-for="(item, index) in providerNameList" :key="index"
          :name="item.name"
          :keywords="item.keywords"
          :providerName="item.providerName"
        ></hotWordList>
      </div>

      <div class="b" v-show="emptyShow">
        <el-empty name="empty" description="暂无数据"></el-empty>
      </div>
    </el-main>
  </div>
</template>

<script>
import { getExternalData } from "@/api/externalData";
import { formatDate } from "@/common/date";

import hotWordList from "@/components/onlineShopGoods/hotWord/hotWordList.vue";

export default {
  name: "hotWord",
  data() {
    return {
      emptyShow: true,
      queryParams: {
        keywords: null, //绿联
        providerNames: ["tb.hot-word","jd.hot-word"],
      },
      providerNameList: [
        // {
        //   keywords: "", //绿联
        //   providerName: "tb.hot-word",
        //   name: "淘宝",
        // },
        // {
        //   keywords: "", //绿联
        //   providerName: "jd.hot-word",
        //   name: "京东",
        // },
      ],
      providerNameSelectList: [
        {
          providerName: "tb.hot-word",
          name: "淘宝",
        },
        {
          providerName: "jd.hot-word",
          name: "京东",
        },
      ],
      rules: {
        keywords: [
          { required: true, message: "请输入相关热词", trigger: "blur" },
        ],
        providerNames: [
          { required: true, message: "请选择平台", trigger: "change" },
        ],
      },
    };
  },
  props: {
    searchKeywords: String,
  },
  watch: {
    searchKeywords: {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old);
        this.queryParams.keywords = newVal;
        this.getExternalData();
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    hotWordList,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  mounted() {},
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  methods: {
    getExternalData() {
      this.$refs["queryParams"].validate((valid) => {
        this.providerNameList = [];
        if (valid) {
          this.queryParams.providerNames.forEach((item, index) => {
            this.providerNameSelectList.forEach((item2, index2) => {
              if (item == item2.providerName) {
                item2.keywords = this.queryParams.keywords;
                this.providerNameList.push(item2);
                this.$forceUpdate();
              }
            });
          });
        } else {
          console.log(valid + "-------------valid");
        }
        this.emptyShow = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}

.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 160px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      display: inline;
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    b {
      min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      float: left;

      span {
        display: inline;
        padding: 0 10px;
      }
    }
    div {
      // width: 50%;
      margin: 0 0 4px 0;
      b {
        min-width: 150px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
    .childWidth {
      display: inline;
      width: calc(100% - 150px);
    }
  }
}
</style>
