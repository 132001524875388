<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-04-14 11:44:03
 * @LastEditTime: 2022-05-16 15:23:48
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/onlineShopGoods/tiktok.vue
-->

<template>
  <el-main>
    <el-tabs v-model="activeName2" @tab-click="handleClick2">
      <el-tab-pane v-for="(item,index) in tabs" :key="index" :label="item.label" :name="item.name"></el-tab-pane>
  </el-tabs>
    <el-collapse v-model="activeNames" @change="handleChange" accordion>
      <el-collapse-item title="抖音商品列表" name="1">
        <el-form
          class="projectFrom"
          :model="queryParamsGoodsSearch"
          ref="tiktok"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryParamsGoodsSearch.keywords"
              placeholder="请输入商品标题关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getExternalDataGoodsSearch(0)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="goodsSearchTableData != null">
          <!-- 表格 -->
          <el-table
            border
            :data="goodsSearchTableData"
            v-loading="goodsSearchTableLoading"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="商品标题" prop="name">
              <template slot-scope="scope">
                <span v-if="scope.row.product_info">
                  <span v-if="scope.row.aweme_info">
                    <el-link
                      v-if="scope.row.aweme_info.share_url"
                      :href="scope.row.aweme_info.share_url"
                      target="_blank"
                      :underline="false"
                      type="primary"
                      >{{ scope.row.product_info.name }}</el-link
                    >
                  </span>
                  <span v-else>{{ scope.row.product_info.name }}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="销量" prop="extra_info" width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.product_info">{{
                  scope.row.product_info.extra_info
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="活动" prop="color">
              <template slot-scope="scope">
                <div v-if="scope.row.product_info">
                  <span
                    v-for="(item, index4) in scope.row.product_info.coupon"
                    :key="index4"
                  >
                    <p>
                      {{ item.first_tag }}
                      <span v-if="item.second_tag"
                        >: {{ item.second_tag }}</span
                      >
                    </p>
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="价格" prop="show_price" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.product_info">
                  {{ scope.row.product_info.show_price }}
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column> -->
            <el-table-column label="店铺名" prop="show_price">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  {{ scope.row.aweme_info.author.nickname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="商品图" prop="img" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.product_info">
                  <img
                    class="picImg"
                    :src="scope.row.product_info.img"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="商品链接" prop="share_url" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  <el-link
                    v-if="scope.row.aweme_info.share_url"
                    :href="scope.row.aweme_info.share_url"
                    target="_blank"
                    :underline="false"
                    >商品链接</el-link
                  >
                </span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openShopInfo(scope.row)"
                  >查看店铺信息</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>

          <div
            class="load flexJA pointer"
            v-if="goodsSearchTableData.length > 0 && hasMore"
            @click="load"
          >
            点击加载更多
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="综合搜索" name="2">
        <el-form
          class="projectFrom"
          :model="queryGeneralInfo"
          ref="tiktok"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryGeneralInfo.keywords"
              placeholder="请输入商品关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getGeneralInfoData(0)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="generalTableData != null">
          <!-- 表格 -->
          <el-table
            border
            :data="generalTableData"
            v-loading="generalTableLoading"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="标题" prop="name">
              <template slot-scope="scope">
                <div v-if="scope.row.type == '999' && scope.row.dynamic_patch">
                  <div
                    v-for="(item, index) in scope.row.dynamic_patch.aweme_list"
                    :key="index"
                  >
                    <p v-if="scope.row.aweme_info">
                      <el-link
                        v-if="scope.row.aweme_info.share_url"
                        :href="scope.row.aweme_info.share_url"
                        target="_blank"
                        :underline="false"
                        type="primary"
                        >{{ item.desc }}</el-link
                      >
                    </p>
                    <p v-else>{{ index + 1 }}.{{ item.desc }}</p>
                  </div>
                </div>
                <span v-if="scope.row.type == '1'">
                  <span v-if="scope.row.aweme_info">
                    <el-link
                      v-if="scope.row.aweme_info.share_url"
                      :href="scope.row.aweme_info.share_url"
                      target="_blank"
                      :underline="false"
                      type="primary"
                    >
                      {{ scope.row.aweme_info.desc }}</el-link
                    >
                  </span>
                  <span v-else>
                    {{ scope.row.aweme_info.desc }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="是否热点信息" prop="extra_info" width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.type">{{
                  scope.row.type == 999 ? "是" : "否"
                }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="活动" prop="color">
              <template slot-scope="scope">
                <div v-if="scope.row.product_info">
                  <span
                    v-for="(item, index4) in scope.row.product_info.coupon"
                    :key="index4"
                  >
                    <p>
                      {{index + 1}}.{{ item.first_tag }}
                      <span v-if="item.second_tag"
                        >: {{ item.second_tag }}</span
                      >
                    </p>
                  </span>
                </div>
              </template>
            </el-table-column> -->

            <el-table-column label="评论" prop="color">
              <template slot-scope="scope">
                <div v-if="scope.row.comment_list">
                  <span
                    v-for="(item, index) in scope.row.comment_list"
                    :key="index"
                  >
                    <p>
                      {{ index + 1 }}.{{ item.user.nickname }}
                      <span v-if="item.text">: {{ item.text }}</span>
                    </p>
                  </span>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="价格" prop="show_price" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.product_info">
                  {{ scope.row.product_info.show_price }}
                </span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column> -->
            <el-table-column label="店铺名" prop="nickname">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  {{ scope.row.aweme_info.author.nickname }}
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="店铺签名" prop="signature">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  {{ scope.row.aweme_info.signature }}
                </span>
              </template>
            </el-table-column> -->

            <el-table-column label="商品图" prop="img" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.product_info">
                  <img
                    class="picImg"
                    :src="scope.row.product_info.img"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="商品链接" prop="share_url" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  <el-link
                    v-if="scope.row.aweme_info.share_url"
                    :href="scope.row.aweme_info.share_url"
                    target="_blank"
                    :underline="false"
                    >商品链接</el-link
                  >
                </span>
              </template>
            </el-table-column> -->
          </el-table>

          <div
            class="load flexJA pointer"
            v-if="generalTableData.length > 0 && generalHasMore"
            @click="loadGeneralInfo"
          >
            点击加载更多
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="直播搜索" name="3">
        <el-form
          class="projectFrom"
          :model="queryLiveInfo"
          ref="tiktok"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryLiveInfo.keywords"
              placeholder="请输入直播间关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getLiveInfoData(0)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="liveSearch.list != null">
          <!-- 表格 -->
          <el-table
            border
            :data="liveSearch.list"
            v-loading="liveSearch.loading"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="直播间名称" prop="nickname">
              <template slot-scope="scope">
                <span v-if="scope.row.lives">
                  {{ scope.row.lives.author.nickname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="所在地" prop="city">
              <template slot-scope="scope">
                <span v-if="scope.row.lives">
                  {{ scope.row.lives.rawdata.owner.city }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="签名" prop="city">
              <template slot-scope="scope">
                <span v-if="scope.row.lives">
                  {{ scope.row.lives.rawdata.owner.signature }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="是否热点信息" prop="extra_info" width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.type">{{
                  scope.row.type == 999 ? "是" : "否"
                }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="链接" prop="share_url" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  <el-link
                    v-if="scope.row.aweme_info.share_url"
                    :href="scope.row.aweme_info.share_url"
                    target="_blank"
                    :underline="false"
                    >商品链接</el-link
                  >
                </span>
              </template>
            </el-table-column> -->
          </el-table>

          <div
            class="load flexJA pointer"
            v-if="liveSearch.list.length > 0 && liveSearch.hasMore"
            @click="loadLive"
          >
            点击加载更多
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="视频搜索" name="4">
        <el-form
          class="projectFrom"
          :model="queryVideoInfo"
          ref="tiktok"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryVideoInfo.keywords"
              placeholder="请输入视频关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getVideoInfoData(0)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="videoSearch.list != null">
          <!-- 表格 -->
          <el-table
            border
            :data="videoSearch.list"
            v-loading="videoSearch.loading"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="标题" prop="name">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  <el-link
                    v-if="scope.row.aweme_info.share_url"
                    :href="scope.row.aweme_info.share_url"
                    target="_blank"
                    :underline="false"
                    type="primary"
                    >{{ scope.row.aweme_info.desc }}</el-link
                  >
                  <span v-else>
                    {{ scope.row.aweme_info.desc }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="是否热点信息" prop="extra_info" width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.type">{{
                  scope.row.type == 999 ? "是" : "否"
                }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="视频链接" prop="share_url" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.aweme_info">
                  <el-link
                    v-if="scope.row.aweme_info.share_url"
                    :href="scope.row.aweme_info.share_url"
                    target="_blank"
                    :underline="false"
                    >视频链接</el-link
                  >
                </span>
              </template>
            </el-table-column> -->
          </el-table>

          <div
            class="load flexJA pointer"
            v-if="videoSearch.list.length > 0 && videoSearch.hasMore"
            @click="loadVideo"
          >
            点击加载更多
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="话题搜索" name="5">
        <el-form
          class="projectFrom"
          :model="queryTopicInfo"
          ref="tiktok"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryTopicInfo.keywords"
              placeholder="请输入话题关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getTopicSearchData(0)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="topicSearch.list != null">
          <!-- 表格 -->
          <el-table
            border
            :data="topicSearch.list"
            v-loading="topicSearch.loading"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="话题名称" prop="name">
              <template slot-scope="scope">
                <span v-if="scope.row.challenge_info">
                  <el-link
                    v-if="scope.row.challenge_info.share_info"
                    :href="scope.row.challenge_info.share_info.share_url"
                    target="_blank"
                    :underline="false"
                    type="primary"
                    >{{ scope.row.challenge_info.cha_name }}</el-link
                  >
                  <span v-else>
                    {{ scope.row.challenge_info.cha_name }}
                  </span>
                </span>
                 
              </template>
            </el-table-column>

            <el-table-column label="话题图片" prop="name">
              <template slot-scope="scope">
                <div v-if="scope.row.challenge_info">
                  <div class="flexFwAc">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="scope.row.challenge_info.hashtag_profile"
                      :preview-src-list="[
                        scope.row.challenge_info.hashtag_profile,
                      ]"
                    >
                    </el-image>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="话题链接" prop="share_url" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.challenge_info">
                  <el-link
                    v-if="scope.row.challenge_info.share_info"
                    :href="scope.row.challenge_info.share_info.share_url"
                    target="_blank"
                    :underline="false"
                    type="primary"
                    >话题链接</el-link
                  >
                </span>
              </template>
            </el-table-column> -->
          </el-table>

          <div
            class="load flexJA pointer"
            v-if="topicSearch.list.length > 0 && topicSearch.hasMore"
            @click="loadTopic"
          >
            点击加载更多
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
      <el-collapse-item title="用户搜索" name="6">
        <el-form
          class="projectFrom"
          :model="queryUserInfo"
          ref="tiktok"
          label-width="120px"
          :rules="rules3"
        >
          <el-form-item label="关键词" prop="keywords">
            <el-input
              type="text"
              v-model="queryUserInfo.keywords"
              placeholder="请输入用户关键字"
              width="200"
              clearable
            ></el-input>
          </el-form-item>

          <div style="margin-left: 80px; float: right">
            <!-- <el-button @click="resetConditions">重置</el-button> -->
            <el-button type="primary" @click="getUserSearchData(0)"
              >查询</el-button
            >
          </div>
        </el-form>
        <div class="a" v-if="userSearch.list != null">
          <!-- 表格 -->
          <el-table
            border
            :data="userSearch.list"
            v-loading="userSearch.loading"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="用户名" prop="name">
              <template slot-scope="scope">
                <span v-if="scope.row.dynamic_patch">
                  {{ scope.row.dynamic_patch.raw_data.user_info.nickname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="基本信息" prop="name">
              <template slot-scope="scope">
                <div v-if="scope.row.dynamic_patch.raw_data">
                  <div
                    v-for="(item, index) in scope.row.dynamic_patch.raw_data
                      .user_info.display_info"
                    :key="index"
                  >
                    <div v-for="(title, index2) in item" :key="index2">
                      <p v-if="typeof title == 'string'">
                        {{ title }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="头像" prop="name">
              <template slot-scope="scope">
                <div v-if="scope.row.dynamic_patch.raw_data">
                  <div class="flexFwAc w100">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="
                        scope.row.dynamic_patch.raw_data.user_info.avatar_thumb
                          .url_list[0]
                      "
                      :preview-src-list="
                        scope.row.dynamic_patch.raw_data.user_info.avatar_thumb
                          .url_list
                      "
                    >
                    </el-image>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div
            class="load flexJA pointer"
            v-if="userSearch.list.length > 0 && userSearch.hasMore"
            @click="loadUser"
          >
            点击加载更多
          </div>
        </div>
        <div v-else class="b">
          <el-empty name="empty" description="暂无数据"></el-empty>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-main>
</template>

<script>
import { getExternalData, getShopSelectList } from "@/api/externalData";
import { formatDate } from "@/common/date";
import { json } from "body-parser";

export default {
  name: "tiktok",
  data() {
    return {
      activeName2:'1',
      tabs:[
        {
          label:'抖音商品搜索',
          name:"1"
        },
        {
          label:'综合搜索',
          name:"2"
        },
        {
          label:'直播搜索',
          name:"3"
        },
        {
          label:'视频搜索',
          name:"4"
        },
        {
          label:'话题搜索',
          name:"5"
        },
        {
          label:'用户搜索',
          name:"6"
        },
      ],
      loading: true,
      detail: null,
      queryParamsGoodsSearch: {
        //商品搜索
        keywords: "",
        providerName: "dy.goods.search",
        cursor: 0,
      },
      queryGeneralInfo: {
        //综合搜索
        keywords: "",
        providerName: "dy.general.search",
        cursor: 0,
      },
      queryLiveInfo: {
        //直播搜索
        keywords: "",
        providerName: "dy.live.search",
        cursor: 0,
      },
      queryVideoInfo: {
        //视频搜索
        keywords: "",
        providerName: "dy.video.search",
        cursor: 0,
      },
      queryTopicInfo: {
        //话题搜索
        keywords: "",
        providerName: "dy.topic.search",
        cursor: 0,
      },
      queryUserInfo: {
        //用户搜索
        keywords: "",
        providerName: "dy.user.search",
        cursor: 0,
      },
      activeNames: "1",
      tableData: null,
      //商品搜索
      goodsSearchTableData: [],
      hasMore: true,
      goodsSearchTableLoading: false,
      queryParamsGoodsSearchCursor: 0,
      //综合搜索
      generalTableData: [],
      generalHasMore: true,
      generalTableLoading: false,
      queryGeneralInfoCursor: 0,
      //直播搜索
      liveSearch: {
        list: [],
        hasMore: true,
        loading: false,
        cursor: 0,
      },
      //视频搜索
      videoSearch: {
        list: [],
        hasMore: true,
        loading: false,
        cursor: 0,
      },
      //话题搜索
      topicSearch: {
        list: [],
        hasMore: true,
        loading: false,
        cursor: 0,
      },
      //用户搜索
      userSearch: {
        list: [],
        hasMore: true,
        loading: false,
        cursor: 0,
      },
      // generalTableData: [],
      // generalHasMore: true,
      // generalTableLoading: false,
      // queryGeneralInfoCursor: 0,

      goodsFeedbackTableData: null,
      shopSelectList: null,
      rules: {
        sellerNickname: [
          { required: true, message: "请输入店铺名", trigger: "blur" },
        ],
      },
      rules2: {
        shopId: [{ required: true, message: "请选择店铺", trigger: "change" }],
        keywords: [
          { required: false, message: "请输入商品标题关键词", trigger: "blur" },
        ],
      },
      rules3: {
        keywords: [
          { required: true, message: "请输入商品关键词", trigger: "blur" },
        ],
      },
      rules4: {
        keywords: [
          { required: false, message: "请输入商品关键词", trigger: "blur" },
        ],
      },
      icons: [
        "#icon-el-icon-xin",
        "#icon-el-icon-zuan",
        "#icon-el-icon-lanhuangguan",
        "#icon-el-icon-jinhuangguan",
      ],
      sellerNicknamePlaceholder: "",
      goodsSearchSorts: [
        {
          id: 0,
          name: "综合排序",
        },
        {
          id: 1,
          name: "销量排序",
        },
        {
          id: 2,
          name: "评价排序",
        },
        {
          id: 3,
          name: "新品排序",
        },
        {
          id: 4,
          name: "价格由高到低",
        },
        {
          id: 5,
          name: "价格由低到高",
        },
      ],
      goodsSearchJdwls: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "抖音物流",
        },
      ],
      goodsSearchStores: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "仅显示有货",
        },
      ],
      filtersSelectList: [
        {
          id: 1,
          name: "免邮",
        },
        {
          id: 2,
          name: "天猫",
        },
        {
          id: 3,
          name: "全球购",
        },
        {
          id: 4,
          name: "消保",
        },
        {
          id: 5,
          name: "手机专享",
        },
        {
          id: 6,
          name: "淘金币",
        },
        {
          id: 7,
          name: "货到付款",
        },
        {
          id: 8,
          name: "7天退换",
        },
        {
          id: 9,
          name: "促销",
        },
      ],
      goodsFeedbackSorts: [
        {
          id: 5,
          name: "默认排序",
        },
        {
          id: 6,
          name: "时间排序",
        },
      ],
      goodsFeedbackTypes: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "差评",
        },
        {
          id: 2,
          name: "中评",
        },
        {
          id: 3,
          name: "好评",
        },
      ],
      goodsFeedbackOnlySku: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      goodsFeedbackTableData1: null,
      shopSelectListParams: {
        name: null,
        type: 2,
      },
    };
  },
  props: {
    searchKeywords: String,
  },
  watch: {
    searchKeywords: {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old);
        if (newVal == old) return false;
        this.queryParamsGoodsSearch.keywords = newVal;
        this.queryGeneralInfo.keywords = newVal;
        this.queryLiveInfo.keywords = newVal;
        this.queryVideoInfo.keywords = newVal;
        this.queryTopicInfo.keywords = newVal;
        this.queryUserInfo.keywords = newVal;
        this.goodsSearchTableData = [];
        this.generalTableData = []
        this.liveSearch.list = [];
        this.videoSearch.list = [];
        this.topicSearch.list = [];
        this.userSearch.list = [];
        if (this.activeNames == 1) {
          this.getExternalDataGoodsSearch(0);
        } else if (this.activeNames == 2) {
          this.getGeneralInfoData(0);
        } else if (this.activeNames == 3) {
          this.getLiveInfoData(0);
        } else if (this.activeNames == 4) {
          this.getVideoInfoData(0);
        } else if (this.activeNames == 5) {
          this.getTopicSearchData(0);
        } else if (this.activeNames == 6) {
          this.getUserSearchData(0);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  mounted() {},
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  methods: {
    
    handleClick2(tab, event){
      console.log(tab, event)
      let val = tab.name;
      this.activeNames = val;
       if (val == 1) {
        if (this.goodsSearchTableData.length > 0) return false;
        this.getExternalDataGoodsSearch(0);
      } else if (val == 2) {
        if (this.generalTableData.length > 0) return false;
        this.getGeneralInfoData(0);
      } else if (val == 3) {
        if (this.liveSearch.list.length > 0) return false;
        this.getLiveInfoData(0);
      } else if (val == 4) {
        if (this.videoSearch.list.length > 0) return false;
        this.getVideoInfoData(0);
      } else if (val == 5) {
        if (this.topicSearch.list.length > 0) return false;
        this.getTopicSearchData(0);
      } else if (val == 6) {
        if (this.userSearch.list.length > 0) return false;
        this.getUserSearchData(0);
      }
      this.$forceUpdate();
    },
    handleChange(val) {
      console.log(val);
      this.activeName2 = val;
      if (val == 1) {
        if (this.goodsSearchTableData.length > 0) return false;
        this.getExternalDataGoodsSearch(0);
      } else if (val == 2) {
        if (this.generalTableData.length > 0) return false;
        this.getGeneralInfoData(0);
      } else if (val == 3) {
        if (this.liveSearch.list.length > 0) return false;
        this.getLiveInfoData(0);
      } else if (val == 4) {
        if (this.videoSearch.list.length > 0) return false;
        this.getVideoInfoData(0);
      } else if (val == 5) {
        if (this.topicSearch.list.length > 0) return false;
        this.getTopicSearchData(0);
      } else if (val == 6) {
        if (this.userSearch.list.length > 0) return false;
        this.getUserSearchData(0);
      }
    },
    loadGeneralInfo() {
      this.getGeneralInfoData(this.queryGeneralInfoCursor);
    },
    load() {
      this.getExternalDataGoodsSearch(this.queryParamsGoodsSearchCursor);
    },
    loadLive() {
      this.getLiveInfoData(this.liveSearch.cursor);
    },
    loadVideo() {
      this.getVideoInfoData(this.videoSearch.cursor);
    },
    loadTopic() {
      this.getTopicSearchData(this.topicSearch.cursor);
    },
    loadUser() {
      this.getUserSearchData(this.userSearch.cursor);
    },
    //1
    getGeneralInfoData(cursor) {
      if (!this.queryGeneralInfo.keywords) return false;
      this.generalTableLoading = true;
      if (cursor == 0) {
        this.generalTableData = [];
      }
      this.queryGeneralInfo.cursor = cursor;
      getExternalData(this.queryGeneralInfo).then((response) => {
        if (response.success) {
          var tableData = response.data.data;
          console.log(tableData);
          let list = [];
          tableData.map((item, index) => {
            if (item.type == 999 && item.hasOwnProperty("dynamic_patch")) {
              let dp = item.dynamic_patch;
              if (dp.hasOwnProperty("aweme_list")) {
                list.push(item);
              }
            } else if (item.type == 1 && item.hasOwnProperty("aweme_info")) {
              let ai = item.aweme_info;
              if (ai.hasOwnProperty("desc")) {
                list.push(item);
              }
            }
          });

          this.generalHasMore = response.data.has_more == 1 ? true : false;
          this.generalTableData = this.generalTableData.concat(list);
          this.queryGeneralInfoCursor = response.data.cursor;
          this.generalTableLoading = false;
        } else {
          this.generalTableData = [];
        }
      });
    },
    //2
    getExternalDataGoodsSearch(cursor) {
      if (!this.queryParamsGoodsSearch.keywords) return false;
      if (cursor == 0) {
        this.goodsSearchTableData = [];
      }
      this.goodsSearchTableLoading = true;
      this.queryParamsGoodsSearch.cursor = cursor;
      getExternalData(this.queryParamsGoodsSearch).then((response) => {
        if (response.success) {
          let tableData = response.data.items;
          tableData.forEach((item, index) => {
            if (item.product_info && item.product_info.extra_info) {
              item.product_info.extra_info =
                item.product_info.extra_info.slice(2);
            }
          });
          this.hasMore = response.data.has_more;
          this.goodsSearchTableData =
            this.goodsSearchTableData.concat(tableData);
          this.queryParamsGoodsSearchCursor = response.data.cursor;
          this.goodsSearchTableLoading = false;
        } else {
          this.goodsSearchTableData = null;
        }
      });
    },
    //3
    getLiveInfoData(cursor) {
      if (!this.queryLiveInfo.keywords) return false;
      if (cursor == 0) {
        this.liveSearch.list = [];
      }
      this.liveSearch.loading = true;
      this.queryLiveInfo.cursor = cursor;
      getExternalData(this.queryLiveInfo).then((response) => {
        if (response.success) {
          let tableData = response.data.data;
          tableData.forEach((item, index) => {
            if (item.lives && item.lives.rawdata) {
              item.lives.rawdata = JSON.parse(item.lives.rawdata);
            }
          });
          this.liveSearch.hasMore = response.data.has_more;
          this.liveSearch.list = this.liveSearch.list.concat(tableData);
          this.liveSearch.cursor = response.data.cursor;
          this.liveSearch.loading = false;
        } else {
          this.liveSearch.list = null;
        }
      });
    },
    getVideoInfoData(cursor) {
      if (!this.queryVideoInfo.keywords) return false;
      if (cursor == 0) {
        this.videoSearch.list = [];
      }
      this.videoSearch.loading = true;
      this.queryVideoInfo.cursor = cursor;
      getExternalData(this.queryVideoInfo).then((response) => {
        if (response.success) {
          let tableData = response.data.data;
          // tableData.forEach((item, index) => {
          //   if (item.product_info && item.product_info.extra_info) {
          //     item.product_info.extra_info =
          //       item.product_info.extra_info.slice(2);
          //   }
          // });
          this.videoSearch.hasMore = response.data.has_more;
          this.videoSearch.list = this.videoSearch.list.concat(tableData);
          this.videoSearch.cursor = response.data.cursor;
          this.videoSearch.loading = false;
        } else {
          this.videoSearch.list = null;
        }
      });
    },
    getTopicSearchData(cursor) {
      if (!this.queryTopicInfo.keywords) return false;
      if (cursor == 0) {
        this.topicSearch.list = [];
      }
      this.topicSearch.loading = true;
      this.queryTopicInfo.cursor = cursor;
      getExternalData(this.queryTopicInfo).then((response) => {
        if (response.success) {
          let tableData = response.data.challenge_list;
          // tableData.forEach((item, index) => {
          //   if (item.product_info && item.product_info.extra_info) {
          //     item.product_info.extra_info =
          //       item.product_info.extra_info.slice(2);
          //   }
          // });
          this.topicSearch.hasMore = response.data.has_more;
          this.topicSearch.list = this.topicSearch.list.concat(tableData);
          this.topicSearch.cursor = response.data.cursor;
          this.topicSearch.loading = false;
        } else {
          this.topicSearch.list = null;
        }
      });
    },
    getUserSearchData(cursor) {
      if (!this.queryUserInfo.keywords) return false;
      if (cursor == 0) {
        this.userSearch.list = [];
      }
      this.userSearch.loading = true;
      this.queryUserInfo.cursor = cursor;
      getExternalData(this.queryUserInfo).then((response) => {
        if (response.success) {
          let tableData = response.data.user_list;
          tableData.forEach((item, index) => {
            if (item.dynamic_patch && item.dynamic_patch.raw_data) {
              item.dynamic_patch.raw_data = JSON.parse(
                item.dynamic_patch.raw_data
              );
            }
          });
          this.userSearch.hasMore = response.data.has_more;
          this.userSearch.list = this.userSearch.list.concat(tableData);
          this.userSearch.cursor = response.data.cursor;
          this.userSearch.loading = false;
        } else {
          this.userSearch.list = null;
        }
      });
    },
    async getExternalData1(list, info) {
      this[`${list}`] = await this.$store
        .dispatch("getExternalData", info)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    openShoProducts() {
      console.log(this.detail);
      this.getShopSelectList(true);
      this.queryParamsShoProducts.shopId = this.detail.shop_shopId;
      this.getExternalDataProductList(
        this.queryParamsShoInfo.pageNum,
        this.queryParamsShoInfo.pageSize
      );
      this.activeNames = ["3"];
    },
    openGoodsFeedbackList(data) {
      console.log(data.wareid);
      this.queryParamsGoodsFeedbackList.productId = data.wareid;
      this.getExternalDataGoodsFeedbackList(
        this.queryParamsGoodsFeedbackList.pageNum,
        this.queryParamsGoodsFeedbackList.pageSize
      );
      this.activeNames = ["4"];
    },
    openShopInfo(data) {
      // console.log(data.userId);
      this.queryParamsShoInfo.shopId = data.shop_id;
      this.rules.sellerNickname[0].required = false;
      this.getExternalData();
      this.activeNames = ["2"];
      this.sellerNicknamePlaceholder = null;
    },
    sellerNicknameChange(data) {
      console.log(data);
      if (this.sellerNicknamePlaceholder != null) {
        this.sellerNicknamePlaceholder = null;
      }
    },
    getShopSelectList(cb) {
      if (cb) {
        getShopSelectList(this.shopSelectListParams).then((response) => {
          if (response.success) {
            this.shopSelectList = response.data;
          } else {
            this.shopSelectList = null;
          }
        });
      }
    },

    getExternalData() {
      getExternalData(this.queryParamsShoInfo).then((response) => {
        if (response.success) {
          let detail = response.data;
          if (detail) {
            if (
              detail.shop_logoUrlNew.indexOf("http") ||
              detail.shop_logoUrlNew.indexOf("https")
            ) {
              // detail.shop_logoUrlNew = "https:" + detail.shopSignImg;
              // detail.diy_shopLatestSignUrl = "https:" + detail.wapIcon;
            }
            // detail.rankNum = Number(detail.rankNum);
            // detail.rankType = Number(detail.rankType);

            this.detail = detail;
          } else {
            this.detail = null;
            this.$message({ type: "warning", message: "暂无店铺数据!" });
          }
        } else {
          this.detail = null;
        }
      });
    },
    getExternalDataProductList(num, pageSize) {
      this.$refs["queryParamsShoProducts"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsShoProducts.pageNum = num;
          this.queryParamsShoProducts.pageSize = pageSize;
          // this.queryParamsShoProducts.shopId = this.detail.id;
          getExternalData(this.queryParamsShoProducts).then((response) => {
            // console.log(this.tableData);
            if (response.success) {
              let tableData = response.data.Paragraph;
              let page = response.data.Summary;
              tableData.forEach((item, index) => {
                if (
                  item.Content.imageurl &&
                  (item.Content.imageurl.indexOf("http") ||
                    item.Content.imageurl.indexOf("https"))
                ) {
                  item.Content.imageurl =
                    "https://img13.360buyimg.com/imgzone/" +
                    item.Content.imageurl;
                }
              });
              this.tableData = tableData;
              this.queryParamsShoProducts.pageNum = Number(page.Page.PageIndex);
              this.queryParamsShoProducts.pageSize = Number(page.Page.PageSize);
              this.queryParamsShoProducts.total = Number(page.ResultCount);
              this.queryParamsShoProducts.pageCount = Number(
                page.Page.PageCount
              );
            } else {
              this.tableData = null;
            }
          });
        }
      });
    },

    getExternalDataGoodsFeedbackList(num, pageSize) {
      this.$refs["queryParamsGoodsFeedbackList"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          this.queryParamsGoodsFeedbackList.pageNum = num;
          this.queryParamsGoodsFeedbackList.pageSize = pageSize;
          getExternalData(this.queryParamsGoodsFeedbackList).then(
            (response) => {
              // console.log(this.tableData);
              if (response.success) {
                let tableData = response.data.comments;
                tableData.forEach((item, index) => {
                  if (
                    item.referenceImage.indexOf("http") ||
                    item.referenceImage.indexOf("https")
                  ) {
                    item.referenceImage =
                      "https://img13.360buyimg.com/imgzone/" +
                      item.referenceImage;
                  }
                });

                this.goodsFeedbackTableData = tableData;
                // this.queryParamsGoodsFeedbackList.pageNum = Number(response.data.page);
                // this.queryParamsGoodsFeedbackList.pageSize = Number(
                //   response.data.pageSize
                // );
                this.queryParamsGoodsFeedbackList.total = Number(
                  response.data.productCommentSummary.GoodCount
                );
                this.queryParamsGoodsFeedbackList.pageCount = Number(
                  response.data.maxPage
                );
              } else {
                this.goodsFeedbackTableData = null;
              }
            }
          );
        }
      });
    },
    handleSizeChange(val) {
      console.log(val);
      this.getExternalDataProductList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.queryParamsShoInfo.pageCount) {
        val = this.queryParamsShoInfo.pageCount;
      }
      this.getExternalDataProductList(val, this.queryParamsShoInfo.pageSize);
    },

    handleSizeChangeGoodsFeedback(val) {
      console.log(val);
      this.getExternalDataGoodsFeedbackList(1, val);
    },
    handleCurrentChangeGoodsFeedback(val) {
      console.log(val);
      if (val > this.queryParamsGoodsFeedbackList.pageCount) {
        val = this.queryParamsGoodsFeedbackList.pageCount;
      }
      this.getExternalDataGoodsFeedbackList(
        val,
        this.queryParamsGoodsFeedbackList.pageSize
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}

.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 48%;
    // min-height: 30px;
    // line-height: 50px;
    // margin: 2px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
      // margin-right: 5px;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    div {
      // width: 50%;
      b {
        min-width: 120px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
  }
}

//
.imgf {
}
.picImg {
  width: 60px;
}
.shopDSRScoremg {
  color: #909399;
  font-size: 14px;
  margin-left: 5px;
}
.openShoProducts {
  margin-left: 5px;
}
.load {
  margin-top: 10px;
}
.avatarImg {
  margin-right: 10px;
}
</style>
