<template>
  <el-main>
    <el-card class="box-card" name="card">
      <b>{{ name }}：</b>
      <div class="a" v-if="detail != null">
        <ul class="bar" >
          <li class="width100">
            <b>查询结果：</b>
            <div class="childWidth" v-if="detail.goodsList.length == 0">
              无数据
            </div>
            <div class="childWidth" v-else>
              <div
                style="padding: 0 2px"
                v-for="(item, index) in detail.goodsList"
                :key="index"
              >
                <p v-if="detail.goodsList.length == 1">{{ item }}</p>
                <p v-else>{{ index + 1 }}:{{ item }}</p>
              </div>
            </div>
          </li>
          <li>
            <b>时间：</b>
            <span>{{ detail.time }}</span>
          </li>
        </ul>
      </div>
      <div v-else class="b">
        <el-empty name="empty" description="暂无数据"></el-empty>
      </div>
    </el-card>
  </el-main>
</template>

<script>
import { getExternalData } from "@/api/externalData";
import { formatDate } from "@/common/date";
export default {
  name: "tbHotWordList",
  data() {
    return {
      detail: null,
      //   queryParams: {
      //     keywords: "", //绿联
      //     providerName: "tb.hot-word",
      //   },
    };
  },
  props: {
    keywords: String,
    providerName: String,
    name: String,
  },
  watch: {
    providerName: {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old);
        let data = {
            keywords:this.keywords,
            providerName:this.providerName, 
        }
        this.getExternalData(data);
      },
      deep: true,
      immediate: true,
    },
    keywords: {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old); 
          let data = {
            keywords:this.keywords,
            providerName:this.providerName, 
        }
        this.getExternalData(data);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  mounted() {},
  //keep-alive的生命周期 ——进入组件
  activated() {
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() { 
  },
  methods: {
    getExternalData(data) {
      getExternalData(data).then((response) => {
        this.detail = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main{
  width: 50%;
}
.el-card{
  // width: 45%; 
}
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}

.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 120px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      display: inline;
      width: calc(100% - 120px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;

      span {
        display: inline;
        padding: 0 10px;
      }
    }
    div {
      // width: 50%;
      margin: 0 0 4px 0;
      b {
        min-width: 120px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
    .childWidth {
      display: inline;
      width: calc(100% - 120px);
    }
  }
}
</style>
