<template>
  <el-main>
    <el-form
      class="projectFrom"
      :model="queryParams"
      ref="queryParams"
      label-width="70px"
    >
      <el-form-item label="关键词" prop="keywords">
        <el-input
          type="text"
          v-model="queryParams.keywords"
          placeholder="支持品类、品牌、品名、店铺等模糊搜索"
          width="300"
          clearable
        ></el-input>
      </el-form-item>
      <div style="margin-left: 80px; float: right">
        <!-- <el-button @click="resetConditions">重置</el-button> -->
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </el-form>
    <el-tabs
      v-model="tabsValue"
      type="border-card"
      lazy
      @tab-click="handleClick"
    >
      <el-tab-pane
        :key="index"
        v-for="(item, index) in tabs"
        :label="item.title"
        :name="item.name"
      >
        <component
          v-if="item.type != 'iframe'"
          :searchKeywords="item.keywords"
          :is="item.content"
          :iframeUrl="item.iframeUrl"
        ></component>
        <div class="baidu" v-if="item.type == 'iframe'">
          <iframe
            v-if="item.iframeUrl"
            ref="item.type"
            :src="item.iframeUrl"
            frameborder="0"
            style="width: 100%; height: 75vh"
          ></iframe>
        </div>
        <!-- <tab-component :index="index" :name="item.content"></tab-component> -->
        <!-- {{ item.content }} -->
      </el-tab-pane>
    </el-tabs>
  </el-main>
</template>

<script>
import hotWord from "@/components/onlineShopGoods/hotWord.vue";
import tbShop from "@/components/onlineShopGoods/tbShop.vue";
import jdShop from "@/components/onlineShopGoods/jdShop.vue";
import tiktok from "@/components/onlineShopGoods/tiktok.vue";
export default {
  name: "onlineShopGoods",
  data() {
    return {
      queryParams: {
        keywords: "",
      },
      tabsValue: "1",
      tabs: [
        {
          title: "热搜排名",
          name: "1",
          content: "hotWord",
          keywords: null,
          type: null,
        },
        {
          title: "淘宝搜索",
          name: "2",
          content: "tbShop",
          keywords: null,
          type: null,
        },
        {
          title: "京东搜索",
          name: "3",
          content: "jdShop",
          keywords: null,
          type: null,
        },
        {
          title: "抖音搜索",
          name: "tiktok",
          content: tiktok,
          iframeUrl: "",
          type: null,
          keywords: null,
        },
        {
          title: "百度搜索",
          name: "baidu",
          content: null,
          iframeUrl: "",
          type: "iframe",
          keywords: null,
        },
        {
          title: "微博搜索",
          name: "weibo",
          content: null,
          iframeUrl: "",
          type: "iframe",
          keywords: null,
        },
        {
          title: "企查查",
          name: "qcc",
          content: null,
          iframeUrl: "",
          type: "iframe",
          keywords: null,
        },
      ],
      tabIndex: 0,
      iframeUrlWeibo: null,
    };
  },
  components: {
    hotWord,
    tbShop,
    jdShop,
    tiktok,
  },
  computed: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  mounted() {},
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  methods: {
    search() {
      console.log(this.queryParams.keywords);
      this.tabs[this.tabIndex].keywords = this.queryParams.keywords;
      if (this.tabsValue == "weibo") {
        this.tabs[5].iframeUrl =
          "https://s.weibo.com/weibo?q=" + this.queryParams.keywords;
      }
    },
    handleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      this.tabIndex = index;
      if (this.queryParams.keywords) {
        this.tabs[index].keywords = this.queryParams.keywords;
      }
      if (tab.name == "baidu") {
        window.open(
          "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
            this.queryParams.keywords,
          "baidu"
        );
      } else if (tab.name == "weibo") {
        this.tabs[5].iframeUrl =
          "https://s.weibo.com/weibo?q=" + this.queryParams.keywords;
      }
      if (tab.name == "qcc") {
        window.open(
          "https://www.qcc.com/web/search?key=" + this.queryParams.keywords,
          "qcc"
        );
      }
    },
  },
  watch: {
    "queryParams.keywords": {
      handler(newVal, old) {
        console.log(newVal);
        console.log(old);
        // this.tabs[this.tabIndex].keywords = this.queryParams.keywords;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 30rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}

.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 48%;
    // min-height: 30px;
    // line-height: 50px;
    // margin: 2px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
      // margin-right: 5px;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100% !important;
    div {
      // width: 50%;
      b {
        min-width: 120px !important;
        text-align: right;
        align-self: baseline;
        float: left;

        span {
          display: inline;
          padding: 0 10px;
        }
      }
    }
  }
}

//
.imgf {
}
.picImg {
  width: 60px;
}
.shopDSRScoremg {
  color: #909399;
  font-size: 14px;
  margin-left: 5px;
}
</style>
